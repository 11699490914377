@import url("https://fonts.googleapis.com/css2?family=Neucha&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap");
@font-face {
  font-family: "BrittanySignature";
  src: url("/public/fonts/BrittanySignature.ttf");
}

body {
  padding: 0;
  margin: 0;
  background-color: #fcfff7;
}

html {
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* color pink #F2779A */

/* GLOBAL Custom STYLE FOR Slider  */

.splide__pagination {
  margin-bottom: -4rem !important;
}

.splide__pagination__page.is-active {
  background: #f2779a !important;
  transform: scale(1);
  z-index: 1;
}

.splide__pagination__page {
  background: #ccc;
  height: 15px !important;
  width: 15px !important;
}

.splide__arrow.splide__arrow--next {
  top: 90% !important;
  background-color: #f7f4f3;
  border-radius: 50%;
  padding: 0.3rem;
}

.splide__arrow.splide__arrow--prev {
  top: 90% !important;
  background-color: #f7f4f3;
  border-radius: 50%;
  padding: 0.3rem;
}

.splide__arrow svg {
  fill: #f2779a !important;
}

.home {
  display: flex;
}

.homeContainer {
  flex: 6;
  display: flex;
  flex-direction: column;
}

/* styles for Projects PAGE */
.container {
  padding: 20px;
}
.projectList {
  margin-top: 50px;
  margin-bottom: 50px;
}

.itemProject {
  margin-top: 10px;
}
.projectDescription {
  font-size: 14px;
}

.projectSpan {
  font-size: 14px;
  font-weight: bold;
}

.itemProject {
  font-size: 14px;
  color: #243ee8;
}

/* styles for Login PAGE */

.loginBtn {
  font-size: 24px;
  color: "#243EE8";
  font-weight: bold;
}

/* styles for Details PAGE */
.detailsList {
  margin-top: 50px;
  margin-bottom: 50px;
}

.detailsName {
  color: #243ee8;
  font-size: 14px;
}

.itemDetails {
  color: #243ee8;
  font-size: 14px;
  margin-top: 10px;
}

.detailsDescription {
  font-size: 14px;
}

.detailsSpan {
  font-size: 14px;
  font-weight: bold;
}

/* Test styles for images page */

.imagesContainer {
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* box-shadow: 0px 15px 15px -10px rgb(0 0 0 / 5%); */
}

.img {
  width: 300px;
  margin: 10px;
}
img {
  max-width: 100%;
}
